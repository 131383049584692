<template>
  <div class="all-height all-width">
    <div class="">
      <v-card
        loader-height="2"
        class="shadow-off"
        :loading="loading"
        :disabled="loading"
      >
        <v-card-text class="" v-if="!notEnabledClient">
          <div class="d-flex mb-2">
            <div class="d-flex align-center" v-if="formatedData.length > 0">
              <span class="display-1">{{ formatedData.length }}</span>
              <span class="ml-2 line-1">Notification<br />Count</span>
            </div>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              small
              class="mb-2"
              @click="createNotification()"
              >Create Notification</v-btn
            >
            <v-btn class="shadow-off" icon @click="refreshData()">
              <v-icon>mdi-refresh</v-icon>
            </v-btn>
          </div>
          <lb-datatablenew
            :headers="headers"
            :items="formatedData"
            :enableslot="['scheduler', 'action', 'statusvalue']"
            :loading="loading"
            v-if="formatedData.length > 0"
          >
            <template v-slot:scheduler="{ item }">
              <div class="d-none">{{ item }}</div>
              {{ "24" }}
            </template>

            <template v-slot:statusvalue="{ item }">
              <v-chip small label class="success" v-if="item.statusvalue === 'Enabled'">
                Enabled
              </v-chip>
              <v-chip small label v-else> Disabled </v-chip>
            </template>
            <template v-slot:action="{ item }">
              <div class="d-flex align-center">
                <v-tooltip
                  bottom
                  content-class="tooltip-bottom"
                  v-if="item.status"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-1"
                      @click.stop="disableEnableNotification(item, 'disable')"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      small
                    >
                      <v-icon class="">mdi-check </v-icon>
                    </v-btn>
                  </template>
                  <span>Email Notifications Disable</span>
                </v-tooltip>

                <v-tooltip bottom content-class="tooltip-bottom" v-else>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mr-1"
                      @click.stop="disableEnableNotification(item, 'enable')"
                      v-bind="attrs"
                      v-on="on"
                      icon
                      small
                    >
                      <v-icon class="">mdi-close</v-icon>
                    </v-btn>
                  </template>
                  <span>Email Notifications Enable</span>
                </v-tooltip>

                <v-tooltip bottom content-class="tooltip-bottom">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      small
                      v-bind="attrs"
                      v-on="on"
                      color=""
                      @click="editNotification(item)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Update</span>
                </v-tooltip>
              </div>
            </template>
          </lb-datatablenew>
          <div v-else class="text-center grey--text title pa-6">
            No data available
          </div>
        </v-card-text>
        <v-card-text v-else class="text-center grey--text title pa-6">
          Notification scheduler is not enabled. Please contact the Admin.
        </v-card-text>
      </v-card>
    </div>
    <lb-dialog
      v-model="createNotificationDialog"
      :loading="createNotificationLoading"
      :heading="featureData._id ? 'Update Notification' : 'Add Notification'"
      width="800"
    >
      <template v-slot:body>
        <div>
          <v-row>
            <v-col cols="6" class="my-0 py-1">
              <span class="font-weight-bold mb-2">Notification: </span>
              <lb-dropdown
                label="Feature Name"
                v-model="featureData.feature"
                :items="listFeature"
                itemtext="name"
                itemvalue="name"
              />
            </v-col>
            <v-col cols="6" class="my-0 py-1">
              <span class="font-weight-bold mb-2">Scheduler: </span>
              <lb-dropdown
                label="24"
                v-model="featureData.scheduler"
                :items="[24]"
                :itemvalue="'24'"
                :disabled="true"
              />
            </v-col>
            <v-col cols="6" class="my-0 py-1">
              <v-switch
                v-model="featureData.status"
                inset
                class="mt-0"
                color="success"
                label="Status"
              ></v-switch>
            </v-col>
          </v-row>
        </div>
      </template>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn
          small
          class="ml-2"
          color="primary"
          @click="updateNotification()"
          v-if="featureData._id"
          >Update Notification</v-btn
        >
        <v-btn
          small
          class="ml-2"
          color="primary"
          @click="addNotification()"
          v-else
        >
          Add Notification</v-btn
        >
      </template>
    </lb-dialog>
  </div>
</template>

<script>
export default {
	data() {
		return {
			loading: false,
			headers: [{
					value: "indexno",
					text: "#",
					sortable: false,
					filterable: false,
				},
				{
					value: "feature",
					text: "Feature",
					sortable: true,
					filterable: true,
					lock: true,
				},
				{
					value: "scheduler",
					text: "Scheduler At",
					sortable: true,
					filterable: true,
					lock: true,
				},
				{
					value: "statusvalue",
					text: "Status",
					sortable: true,
					filterable: true,
					lock: true,
				},
				{
					value: "action",
					text: "Action",
					sortable: false,
					filterable: false,
					lock: true,
				},
			],
			formatedData: [],
			listNotification: [],
			listFeature: [],
			createNotificationDialog: false,
			createNotificationLoading: false,
			featureData: {
				scheduler: "24",
				status: true,
			},
			notEnabledClient: false,
		};
	},
	created() {
		this.refreshData();
	},
	methods: {
		async refreshData() {
			await this.getfeatureData();
			await this.getNotificationData();
		},
		dataformate() {
			let ObjectTemp = {};
			let ArrayTemp = [];
			this.listNotification.forEach((item, index) => {
				ObjectTemp = {
					__keys: index,
					indexno: index,
					_id: item._id,
					feature: item.feature,
					status: item.status,
					statusvalue: this.statuesCheck(item.status),
					scheduler: "24",
				};
				ArrayTemp.push(ObjectTemp);
			});
			this.formatedData = ArrayTemp;
		},
		statuesCheck(status) {
			return status ? "Enabled" : "Disabled";
		},
		async getNotificationData() {
        this.loading = true;
        try {
            const { data } = await this.axios.post("/v2/varianceanalysis/analysis/getAllFeatures");
            if (data?.status !== "success" || !data?.data) {
            throw new Error(data?.message ?? "Unexpected API response format!");
            }
            if (data.message === "Scheduler not enabled") {
                this.notEnabledClient = true;
                return
            }
            this.listNotification = data.data;
            const featureSet = new Set(this.listNotification.map(({ feature }) => feature));
            this.listFeature = this.listFeature.filter(({ name }) => !featureSet.has(name));
            this.dataformate();
        } catch (err) {
            this.$store.commit("sbError", err.message ?? "Unknown error!");
            console.error("API Error:", err);
        } finally {
            this.loading = false;
        }
        },
		async getfeatureData() {
			this.loading = true;
			try {
				const response = await this.axios.post(
					"/v2/varianceanalysis/analysis/getAllAvailableFeatures", {
						type: "commentary",
					}
				);
				if (response?.data?.status !== "success" || !response.data.data) {
					throw new Error(
						response?.data?.message || "Unexpected API response format!"
					);
				}
				this.listFeature = response.data.data;
			} catch (err) {
				this.$store.commit("sbError", err.message || "Unknown error!");
				console.error("API Error:", err);
			} finally {
				this.loading = false;
			}
		},
		createNotification() {
			this.createNotificationDialog = true;
			this.featureData = {
				scheduler: "24",
				status: true,
				feature: "",
			};
		},

		async disableEnableNotification(item, type) {
            if (!item) return;
            
            console.log(item, type);
            const flag = type === "enable";

            const data = {
                _id: item._id,
                schedulerTiming: "0 0 * * *",
                flag,
                type: "commentary",
                feature: item.feature,
            };

            try {
                const response = await this.axios.post("v2/varianceanalysis/approvals/updateOrCreateSchedulerFeature",{ featureDetails: data });
                if (response?.data?.status !== "success" || !response?.data?.data) {
                throw new Error(response?.data?.message ?? "Unexpected API response format!");
                }

                this.createNotificationDialog = false;
                this.getNotificationData();
            } catch (err) {
                this.$store.commit("sbError", err.message ?? "Unknown error!");
                console.error("API Error:", err);
            } finally {
                this.loading = false;
            }
        },

		editNotification(item) {
			if (!item) return;
			this.createNotificationDialog = true;
			this.featureData = {
				scheduler: item.scheduler,
				status: item.status,
				feature: item.feature,
				_id: item._id
			};
		},
		async updateNotification() {
			let data = {
				_id: this.featureData._id,
				schedulerTiming: "0 0 * * *",
				flag: this.featureData.status,
				type: "commentary",
				feature: this.featureData.feature,
			};
			try {
				const response = await this.axios.post(
					"v2/varianceanalysis/approvals/updateOrCreateSchedulerFeature", {
						featureDetails: data,
					}
				);
				if (response?.data?.status !== "success" || !response.data.data) {
					throw new Error(response?.data?.message || "Unexpected API response format!");
				} else {
					this.createNotificationDialog = false;
					this.getNotificationData();
				}
			} catch (err) {
				this.$store.commit("sbError", err.message || "Unknown error!");
				console.error("API Error:", err);
			} finally {
				this.loading = false;
			}
		},
		async addNotification() {
			let data = {
				schedulerTiming: "0 0 * * *",
				flag: this.featureData.status,
				type: "commentary",
				feature: this.featureData.feature,
			};
			this.createNotificationLoading = true;
			try {
				const response = await this.axios.post(
					"v2/varianceanalysis/approvals/updateOrCreateSchedulerFeature", {
						featureDetails: data,
					}
				);
				if (response?.data?.status !== "success" || !response.data.data) {
					throw new Error(
						response?.data?.message || "Unexpected API response format!"
					);
				} else {
					this.createNotificationDialog = false;
					this.getNotificationData();
				}
			} catch (err) {
				this.$store.commit("sbError", err.message || "Unknown error!");
				console.error("API Error:", err);
			} finally {
				this.createNotificationLoading = false;
			}
		},
	},
};
</script>


<style>
</style>